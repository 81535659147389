const mobileMenuToggle = document.getElementById('mobile-menu-toggle');

class ToggleElement {
  constructor(el) {
    this._el = el;
    this._content = document.getElementById(this._el.dataset.content);
    // this._height = this._content.clientHeight + 8; // to include the margin
    this.toggle = (e) => {
      e.preventDefault();
      if (this._content.classList.contains('open')) {
        this._content.style.height = '0px';
        this._content.setAttribute('data-collapsed', 'true');
        this._content.classList.remove('open');
        this._el.classList.remove('open');
      } else {
        // this was causing an issue when resizing as the items on mobile are hidden their height is 0 when measuring
        // this._content.style.height = `${this._height}px`;
        this._content.style.height = 'initial';
        this._content.setAttribute('data-collapsed', 'false');
        this._content.classList.add('open');
        this._el.classList.add('open');
      }
    };

    this._el.addEventListener('click', this.toggle);
  }

}

window.addEventListener('load', toggleInit)

function toggleInit() {
  const t = document.querySelectorAll('.toggle');
  const toggles = [];

  if (t.length > 0) {
    for (let temp of t) {
      const toggle = new ToggleElement(temp);
      toggle._content.style.height = '0px';
      toggles.push(toggle);
    }
  }
}


if( mobileMenuToggle ) {
  mobileMenuToggle.addEventListener('click', toggleMobileMenu);
}

function toggleMobileMenu() {
  const mainNav = document.getElementById('main-nav');
  const mainHeader = document.querySelector('header');
  const docBody = document.querySelector('body');
  mobileMenuToggle.classList.toggle('open');
  mainNav.classList.toggle('open');
  mainHeader.classList.toggle('fixed');
  docBody.classList.toggle('disable-scroll');
}